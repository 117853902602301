// src/components/TemplateSelector/AddTemplateModal.js

import React from 'react';
import { Modal, Form, Input, Checkbox, Button, Upload, Row, Col, message, Spin, Tooltip, Typography } from 'antd';
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Title } = Typography;

const AddTemplateModal = ({
    visible,
    onCancel,
    onSave,
    saving,
    form,
    themeMode,
    mandatory = false, // New prop with default value
}) => {
    // Custom validator to ensure exactly one of file or free_text is provided
    const validateEitherFileOrText = (_, value) => {
        const file = form.getFieldValue('file');
        const freeText = form.getFieldValue('free_text');
        if ((file && file.length > 0) && (freeText && freeText.trim() !== '')) {
            return Promise.reject(new Error('Please provide either a file or paste your template, not both.'));
        }
        if ((!file || file.length === 0) && (!freeText || freeText.trim() === '')) {
            return Promise.reject(new Error('Please provide either a file or paste your template.'));
        }
        return Promise.resolve();
    };

    // Determine if the modal can be closed
    const isClosable = !mandatory;

    return (
        <Modal
            title={
                <Title level={5} style={{ marginTop: 0, marginBottom: "25px" }}>
                    Let's Create your Custom Template
                    <Tooltip
                        placement="top"
                        title="You can now upload one of your clinical notes or templates to create a custom way of writing notes. Once you upload a note or template, our algorithms will analyze its structure and content to learn your unique writing style. This marks the first step in tailoring the system to you. For every 20 notes you edit, we’ll further refine the training, ensuring your notes become more aligned with your preferences over time."
                    >
                        <InfoCircleOutlined style={{ marginLeft: "15px" }} />
                    </Tooltip>
                </Title>
            }
            visible={visible}
            onCancel={isClosable ? onCancel : null} // Only allow onCancel if closable
            footer={null} // We'll use Form's submit button
            destroyOnClose
            centered
            maskClosable={isClosable} // Control mask closability
            closable={isClosable} // Control the X button
            className={themeMode === 'dark' ? 'dark-modal' : 'light-modal'}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSave}
                initialValues={{
                    public: false,
                }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        { required: true, message: 'Please enter the template name' },
                        { max: 100, message: 'Name cannot exceed 100 characters' },
                    ]}
                >
                    <Input placeholder="Enter template name" />
                </Form.Item>

                <Form.Item
                    label="Short Description"
                    name="short_description"
                    rules={[
                        { required: true, message: 'Please enter a short description' },
                        { max: 200, message: 'Description cannot exceed 200 characters' },
                    ]}
                >
                    <Input placeholder="Enter short description" />
                </Form.Item>

                <Form.Item name="public" valuePropName="checked">
                    <Checkbox>Make this template public</Checkbox>
                </Form.Item>

                <Form.Item
                    label="Upload a clinical note or template that represents your style (.txt, .pdf, .docx)"
                    name="file"
                    valuePropName="file"
                    getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
                    rules={[
                        {
                            validator: validateEitherFileOrText,
                        },
                    ]}
                    dependencies={['free_text']}
                >
                    <Upload
                        beforeUpload={() => false} // Prevent automatic upload
                        maxCount={1}
                        accept=".txt, .pdf, .docx"
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="Or copy paste it"
                    name="free_text"
                    rules={[
                        {
                            validator: validateEitherFileOrText,
                        },
                    ]}
                    dependencies={['file']}
                >
                    <TextArea rows={4} placeholder="Paste your template" />
                </Form.Item>

                <Form.Item>
                    <Row gutter={16} justify="end">
                        {!mandatory && (
                            <Col>
                                <Button onClick={onCancel}>Cancel</Button>
                            </Col>
                        )}
                        <Col>
                            <Button type="primary" htmlType="submit" loading={saving}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddTemplateModal;
