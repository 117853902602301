// src/components/TemplateSelector/YourCustomTemplate.js

import React, { useState, useEffect, useContext } from 'react';
import {
    Typography,
    Divider,
    Spin,
    Row,
    Col,
    Button,
    Modal,
    Form,
    Input,
    Checkbox,
    message,
    Space,
    Radio,
    Upload,
    Card,
    Empty
} from 'antd';
import { PlusOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useAuth } from '../../../../auth/AuthContext'; // Ensure the correct path
import ThemeContext from '../../../../context/ThemeContext';
import '../../../../assets/css/ModalStyle.css';

// Import the new modal components
import AddTemplateModal from './AddTemplateModal/AddTemplateModal';
import EditTemplateModal from './EditTemplateModal/EditTemplateModal';

const { Text, Title } = Typography;
const { TextArea } = Input;

const YourCustomTemplate = ({hasActiveSubscription}) => {
    const { themeMode } = useContext(ThemeContext);
    const [loading, setLoading] = useState(false); // Loading state for fetching templates
    const [modalVisible, setModalVisible] = useState(false); // Control Add modal visibility
    const [editModalVisible, setEditModalVisible] = useState(false); // Control Edit modal visibility
    const [saving, setSaving] = useState(false); // Loading state for saving (both Add and Edit)
    const [form] = Form.useForm(); // Ant Design form instance for Add
    const [editForm] = Form.useForm(); // Ant Design form instance for Edit
    const [templates, setTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [editTemplate, setEditTemplate] = useState(null); // Template being edited
    const [editLoading, setEditLoading] = useState(false); // Loading state for fetching template data in Edit

    const auth = useAuth(); // Access authentication context

    // Function to open the Add modal
    const showModal = () => {
        setModalVisible(true);
    };

    // Function to close the Add modal
    const handleCancel = () => {
        setModalVisible(false);
        form.resetFields(); // Reset form fields when modal is closed
    };

    // Function to open the Edit modal and fetch the latest template data
    const showEditModal = async (template) => {
        setEditTemplate(null); // Reset previous template data
        setEditLoading(true); // Start loading
        setEditModalVisible(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/templates/${template._id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
            });

            if (response.ok) {
                const templateData = await response.json();
                setEditTemplate(templateData);
                // Set initial values in the edit form
                editForm.setFieldsValue({
                    name: templateData.name,
                    short_description: templateData.short_description,
                    free_text: templateData.free_text,
                    public: templateData.public,
                });
            } else {
                const errorData = await response.json();
                message.error(`Failed to fetch template data: ${errorData.message || 'Unknown error'}`);
                setEditModalVisible(false); // Close modal if fetching fails
            }
        } catch (error) {
            console.error('Error fetching template data:', error);
            message.error('An error occurred while fetching the template data.');
            setEditModalVisible(false); // Close modal if fetching fails
        }
        setEditLoading(false); // End loading
    };

    // Function to close the Edit modal
    const handleEditCancel = () => {
        setEditModalVisible(false);
        setEditTemplate(null);
        editForm.resetFields();
    };

    // Function to handle Add form submission
    const handleSave = async (values) => {
        setSaving(true); // Start saving
        try {
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('short_description', values.short_description);
            formData.append('public', values.public || false);
            if (values.file && values.file.length > 0) {
                formData.append('file', values.file[0].originFileObj);
            }
            if (values.free_text) {
                formData.append('free_text', values.free_text);
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/templates/create/file`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                message.success('Custom template created successfully!');
                await fetchTemplates(); // Refresh the templates list
                handleCancel(); // Close Add modal
                form.resetFields(); // Reset form
            } else {
                const errorData = await response.json();
                message.error(`Failed to create template: ${errorData.detail || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error creating custom template:', error);
            message.error('An error occurred while creating the template.');
        }
        setSaving(false); // End saving
    };

    // Function to handle Edit form submission
    const handleEditSave = async (values) => {
        setSaving(true); // Start saving
        try {
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('short_description', values.short_description);
            formData.append('public', values.public || false);
            if (values.file && values.file.length > 0) {
                formData.append('file', values.file[0].originFileObj);
            }
            if (values.free_text) {
                formData.append('free_text', values.free_text);
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/templates/${editTemplate._id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                message.success('Template updated successfully!');
                await fetchTemplates(); // Refresh the templates list
                handleEditCancel(); // Close Edit modal
                editForm.resetFields(); // Reset edit form
            } else {
                const errorData = await response.json();
                message.error(`Failed to update template: ${errorData.detail || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error updating template:', error);
            message.error('An error occurred while updating the template.');
        }
        setSaving(false); // End saving
    };

    // Function to handle Delete
    const handleDelete = (template) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this template?',
            content: `Template Name: ${template.name}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
            onOk: async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/templates/${template._id}`, {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Bearer ${auth.user['access_token']}`,
                        },
                    });

                    if (response.ok) {
                        const result = await response.json();
                        message.success('Template deleted successfully!');
                        // If the deleted template was selected, reset the selection
                        if (selectedTemplateId === template._id) {
                            setSelectedTemplateId(null);
                        }
                        await fetchTemplates(); // Refresh the templates list
                    } else {
                        const errorData = await response.json();
                        message.error(`Failed to delete template: ${errorData.detail || 'Unknown error'}`);
                    }
                } catch (error) {
                    console.error('Error deleting template:', error);
                    message.error('An error occurred while deleting the template.');
                }
            },
            onCancel() {
                console.info('Delete cancelled');
            },
        });
    };

    // Function to fetch custom templates
    const fetchTemplates = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/templates`, {
                headers: {
                    Authorization: `Bearer ${auth.user['access_token']}`,
                },
                method: 'GET',
            });

            if (response.ok) {
                const result = await response.json();
                const userDetails = localStorage.getItem("userDetails");
                const user = userDetails && JSON.parse(userDetails);
                const userId = user?.user_id;

                // Filter templates created by the current user
                const userTemplates = result.data.filter(r => r.user_id === userId && r.type === 'customizedFromScratch');
                setTemplates(userTemplates);

                // Fetch user preferences
                const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/get_user`, {
                    headers: {
                        Authorization: `Bearer ${auth.user['access_token']}`,
                    },
                    method: 'GET',
                });

                if (userResponse.ok) {
                    const userPref = await userResponse.json();
                    if (userPref.preferred_template_id) {
                        setSelectedTemplateId(userPref.preferred_template_id);
                    } else if (userTemplates.length > 0) {
                        setSelectedTemplateId(userTemplates[0]._id);
                    }
                } else if (userTemplates.length > 0) {
                    setSelectedTemplateId(userTemplates[0]._id);
                }
            } else {
                message.error('Failed to fetch custom templates');
            }
        } catch (error) {
            console.error('Error fetching custom templates', error);
            message.error('Error fetching custom templates');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Function to handle template selection
    const handleChange = (e) => {
        setSelectedTemplateId(e.target.value);
    };

    // Function to handle saving the selected template as preferred
    const handleSubmit = async () => {
        setSaving(true); // Start saving
        try {
            const formData = new FormData();
            formData.append('template_id', selectedTemplateId);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/templates/user/select`, {
                headers: {
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                message.success('Selected template saved successfully');
            } else {
                const errorData = await response.json();
                message.error(`Failed to save the selected template: ${errorData.detail || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error saving the selected template:', error);
            message.error('Error saving the selected template');
        }
        setSaving(false); // End saving
    };

    // Function to show confirmation before saving
    const showConfirm = () => {
        Modal.confirm({
            title: 'Are you sure you want to save the selected template?',
            content: 'This action will update your preferred custom summary template.',
            okText: 'Yes',
            okType: 'primary',
            cancelText: 'No',
            centered: true,
            className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
            onOk() {
                handleSubmit();
            },
            onCancel() {
                console.info('Save cancelled');
            },
        });
    };

    return (
        <>
            {/* Title and Divider */}
            {/* <Title level={2} style={{ marginTop: '0px', paddingLeft: '20px' }}>
                Your Custom Templates
            </Title>
            <Divider style={{ marginTop: '0px', paddingLeft: '20px' }} />
             */}
            {/* Loading Indicator */}
            {loading ? (
                <Spin
                    size="large"
                    style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                />
            ) : (
                <Row gutter={[16, 16]}>
                    {/* Add Button */}
                    <Col span={24} style={{ padding: '10px 30px' }}>
                        <Button type="primary" icon={<PlusOutlined />} onClick={showModal} disabled={!hasActiveSubscription}>
                            Create a New Custom Template
                        </Button>
                    </Col>

                    {/* Display Custom Templates */}
                    <Col span={24}>
                        {templates.length > 0 ? (
                            <Radio.Group
                                onChange={handleChange}
                                value={selectedTemplateId}
                                style={{ width: '100%', padding: '10px 20px' }}
                            >
                                <Row gutter={[16, 16]}>
                                    {templates.map((template) => (
                                        <Col xs={24} sm={12} key={template._id}>
                                            <Card
                                                bordered={false}
                                                style={{
                                                    border:
                                                        selectedTemplateId === template._id
                                                            ? '2px solid #2fed78'
                                                            : '1px solid #d9d9d9',
                                                    borderRadius: '4px',
                                                    cursor: 'pointer',
                                                    transition: 'border 0.3s ease',
                                                }}
                                                onClick={() => setSelectedTemplateId(template._id)}
                                            >
                                                <Radio value={template._id} style={{ width: '100%' }}>
                                                    <Space direction="vertical">
                                                        <Text strong>{template.name}</Text>
                                                        <Text type="secondary">
                                                            {template.short_description
                                                                ? template.short_description
                                                                : 'No description available.'}
                                                        </Text>
                                                    </Space>
                                                </Radio>
                                                <Space style={{ marginTop: '10px' }}>
                                                    {/* <Button
                                                        type="link"
                                                        icon={<EditOutlined />}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent selecting the template
                                                            showEditModal(template);
                                                        }}
                                                    /> */}
                                                    <Button
                                                        type="link"
                                                        danger
                                                        icon={<DeleteOutlined />}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent selecting the template
                                                            handleDelete(template);
                                                        }}
                                                    />
                                                </Space>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Radio.Group>
                        ) : (
                            <Empty 
                                image={Empty.PRESENTED_IMAGE_SIMPLE} 
                                description={
                                    <Typography.Text>
                                        No Template Found
                                    </Typography.Text>
                                }
                                style={{ marginTop: '40px' }}
                            />
                        )}
                    </Col>

                    {/* Save Button */}
                    {templates.length > 0 && (
                        <Col
                            span={24}
                            style={{ textAlign: 'right', padding: '0px 25px', marginTop: '20px' }}
                        >
                            <Button type="primary" onClick={showConfirm} loading={saving} disabled={!hasActiveSubscription}>
                                Save
                            </Button>
                        </Col>
                    )}
                </Row>
            )}

            {/* Add Template Modal */}
            <AddTemplateModal
                visible={modalVisible}
                onCancel={handleCancel}
                onSave={handleSave}
                saving={saving}
                form={form}
                themeMode={themeMode}
            />

            {/* Edit Template Modal */}
            <EditTemplateModal
                visible={editModalVisible}
                onCancel={handleEditCancel}
                onSave={handleEditSave}
                saving={saving}
                form={editForm}
                themeMode={themeMode}
                loading={editLoading}
                template={editTemplate}
            />
        </>
    );
}

export default YourCustomTemplate;
