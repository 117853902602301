// src/components/SubscriptionStatus.js

import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Typography, message, Spin, Modal, Divider } from 'antd';
import {
  DollarOutlined,
  CheckCircleOutlined,
  StopOutlined,
  ReloadOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import { useAuth } from '../../../auth/AuthContext';
import PaymentModal from '../../PaymentModal/PaymentModal'; // Ensure correct path
import './YourSubscription.css'; // Import custom CSS

import { loadStripe } from '@stripe/stripe-js';

const { Title, Text } = Typography;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const YourSubscription = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState(null);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentAction, setPaymentAction] = useState('activate'); // 'activate', 'renew', or 'reactivate'

  useEffect(() => {
    fetchSubscriptionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch the current subscription status
  const fetchSubscriptionStatus = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/payments/subscription-status`,
        {
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
          method: 'GET',
        }
      );

      if (response.ok) {
        const result = await response.json();
        setSubscription(result);
      } else {
        const errorData = await response.json();
        message.error(
          `Failed to fetch subscription status: ${
            errorData.detail || 'Unknown error'
          }`
        );
        setSubscription(null); // Reset subscription if fetch fails
      }
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      message.error('Error fetching subscription status.');
      setSubscription(null); // Reset subscription on error
    } finally {
      setLoading(false);
    }
  };

  // Handle Cancel Subscription
  const handleCancelSubscription = () => {
    Modal.confirm({
      title: 'Cancel Subscription',
      content:
        'Are you certain you want to cancel your subscription? Please note that access to premium features will end at the conclusion of your current billing cycle.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        setLoading(true);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/payments/cancel-subscription`,
            {
              headers: {
                Authorization: `Bearer ${auth.user['access_token']}`,
              },
              method: 'POST',
            }
          );

          if (response.ok) {
            const result = await response.json();
            message.success('Subscription canceled successfully.');
            fetchSubscriptionStatus();
          } else {
            const errorData = await response.json();
            message.error(
              `Failed to cancel subscription: ${
                errorData.detail || 'Unknown error'
              }`
            );
          }
        } catch (error) {
          console.error('Error canceling subscription:', error);
          message.error('Error canceling subscription.');
        } finally {
          setLoading(false);
        }
      },
    });
  };

  // Handle Renew Subscription
  const handleRenewSubscription = () => {
    setPaymentAction('renew');
    setPaymentModalVisible(true);
  };

  // Handle Reactivate Subscription
  const handleReactivateSubscription = () => {
    setPaymentAction('reactivate');
    setPaymentModalVisible(true);
  };

  // Handle Activate Subscription
  const handleActivateSubscription = () => {
    setPaymentAction('activate');
    setPaymentModalVisible(true);
  };

  // Handle Payment Modal Close
  const handlePaymentModalClose = () => {
    setPaymentModalVisible(false);
  };

  // Handle Payment Success (After activating, renewing, or reactivating)
  const handlePaymentSuccess = () => {
    setPaymentModalVisible(false);
    fetchSubscriptionStatus();
  };

  // Handle Payment Action (Activate, Renew, or Reactivate)
  const handlePaymentAction = async () => {
    setPaymentLoading(true);
    try {
      if (paymentAction === 'reactivate') {
        // Reactivate subscription without Stripe checkout
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/payments/reactivate-subscription`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${auth.user['access_token']}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          message.success('Subscription reactivated successfully.');
          handlePaymentSuccess();
        } else {
          const errorData = await response.json();
          message.error(
            `Failed to reactivate subscription: ${
              errorData.detail || 'Unknown error'
            }`
          );
        }
      } else {
        // Handle 'activate' and 'renew' via Stripe checkout
        const price_id = `${process.env.REACT_APP_PLAN_ID}`; // Replace with your actual price ID from Stripe
        const plan_name = `${process.env.REACT_APP_PLAN_NAME}`; // Replace with your actual plan name

        const formData = new FormData();
        formData.append('price_id', price_id);
        formData.append('plan_name', plan_name);
        formData.append('action', paymentAction); // Send the action to the backend if needed

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/payments/create-checkout-session`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${auth.user['access_token']}`,
              // Do not set 'Content-Type' header when using FormData
            },
            body: formData,
          }
        );

        if (response.ok) {
          const result = await response.json();
          const { sessionId } = result;

          const stripe = await stripePromise;
          const { error } = await stripe.redirectToCheckout({ sessionId });
          if (error) {
            message.error(error.message);
          }
        } else {
          const errorData = await response.json();
          message.error(
            `Failed to create checkout session: ${
              errorData.detail || 'Unknown error'
            }`
          );
        }
      }
    } catch (error) {
      console.error('Error handling payment action:', error);
      message.error('Error handling payment action.');
    }
    setPaymentLoading(false);
  };

  // Render the component based on subscription status
  const renderContent = () => {
    if (loading) {
      return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
          <Spin size="large" />
        </div>
      );
    }

    if (!subscription) {
      // No subscription information available
      return (
        <Row justify="center">
          <Col xs={24} sm={24} md={12} lg={8}>
            <Card
              title="Premium Plan"
              bordered={false}
              actions={[
                <Button
                  type="primary"
                  icon={<DollarOutlined />}
                  onClick={handleActivateSubscription}
                >
                  Activate Subscription
                </Button>,
              ]}
              hoverable
              className="subscription-card"
            >
              <Title level={4}>$ {subscription?.plan_price ||  "199.00"} USD / Month</Title>
              <Text>
                Access all premium features, including unlimited interviews,
                detailed analytics, and priority support.
              </Text>
            </Card>
          </Col>
        </Row>
      );
    }

    const { plan_name, current_status, current_period_end } = subscription;
    const expiryDate = new Date(current_period_end);
    const now = new Date();

    if (current_status === 'active') {
      // Active subscription
      return (
        <Row justify="center">
          <Col xs={24} sm={24} md={16} lg={12}>
            <Card
              title="Your Active Subscription"
              bordered={false}
              actions={[
                <Button
                  type="primary"
                  icon={<CloseCircleOutlined />}
                  onClick={handleCancelSubscription}
                  danger
                >
                  <b>Cancel Subscription</b>
                </Button>,
              ]}
              className="subscription-card"
              hoverable
            >
              <Row align="middle" gutter={[16, 16]}>
                <Col>
                  <CheckCircleOutlined style={{ fontSize: '48px', color: '#52c41a' }} />
                </Col>
                <Col>
                  <Title level={4}>{'Premium Plan'}</Title>
                  <Text strong>Price:</Text> <Text>${subscription?.plan_price ||  "199.00"} USD / Month</Text>
                </Col>
              </Row>
              <Divider />
              <Text>
                <b>Plan Details:</b>
              </Text>
              <ul>
                <li>Unlimited interviews</li>
                <li>Detailed analytics</li>
                <li>Priority support</li>
              </ul>
              <Divider />
              <Text>
                <b>Next Billing Date:</b> {expiryDate.toLocaleDateString()}
              </Text>
            </Card>
          </Col>
        </Row>
      );
    } else if (current_status === 'canceled') {
      if (expiryDate > now) {
        // Subscription is canceled but not yet expired - show Reactivate button
        return (
          <Row justify="center">
            <Col xs={24} sm={24} md={16} lg={12}>
              <Card
                title="Your Canceled Subscription"
                bordered={false}
                actions={[
                  <Button
                    type="primary"
                    icon={<ReloadOutlined />}
                    onClick={handleReactivateSubscription}
                  >
                    Reactivate Subscription
                  </Button>,
                ]}
                className="subscription-card"
                hoverable
              >
                <Row align="middle" gutter={[16, 16]}>
                  <Col>
                    <StopOutlined style={{ fontSize: '48px', color: '#ff4d4f' }} />
                  </Col>
                  <Col>
                    <Title level={4}>{'Premium Plan'}</Title>
                    <Text strong>Price:</Text> <Text>${subscription?.plan_price ||  "199.00"} USD / Month</Text>
                  </Col>
                </Row>
                <Divider />
                <Text>
                  <b>Plan Details:</b>
                </Text>
                <ul>
                  <li>Unlimited interviews</li>
                  <li>Detailed analytics</li>
                  <li>Priority support</li>
                </ul>
                <Divider />
                <Text type="danger">
                  Your subscription has been canceled and will expire on{' '}
                  {expiryDate.toLocaleDateString()}.
                </Text>
              </Card>
            </Col>
          </Row>
        );
      } else {
        // Subscription is canceled and expired - show Renew button
        return (
          <Row justify="center">
            <Col xs={24} sm={24} md={16} lg={12}>
              <Card
                title="Your Canceled Subscription"
                bordered={false}
                actions={[
                  <Button
                    type="primary"
                    icon={<ReloadOutlined />}
                    onClick={handleRenewSubscription}
                  >
                    Renew Subscription
                  </Button>,
                ]}
                className="subscription-card"
                hoverable
              >
                <Row align="middle" gutter={[16, 16]}>
                  <Col>
                    <StopOutlined style={{ fontSize: '48px', color: '#ff4d4f' }} />
                  </Col>
                  <Col>
                    <Title level={4}>{'Premium Plan'}</Title>
                    <Text strong>Price:</Text> <Text>${subscription?.plan_price ||  "199.00"} USD / Month</Text>
                  </Col>
                </Row>
                <Divider />
                <Text>
                  <b>Plan Details:</b>
                </Text>
                <ul>
                  <li>Unlimited interviews</li>
                  <li>Detailed analytics</li>
                  <li>Priority support</li>
                </ul>
                <Divider />
                <Text type="danger">
                  Your subscription has been canceled and expired on{' '}
                  {expiryDate.toLocaleDateString()}.
                </Text>
              </Card>
            </Col>
          </Row>
        );
      }
    } else {
      // Other statuses
      return (
        <Row justify="center">
          <Col xs={24} sm={24} md={16} lg={12}>
            <Card
              title="Subscription Status"
              bordered={false}
              className="subscription-card"
              hoverable
            >
              <Text>
                Unable to determine subscription status. Please try again later.
              </Text>
            </Card>
          </Col>
        </Row>
      );
    }
  };

  return (
    <>
      {renderContent()}

      {/* Payment Modal */}
      <PaymentModal
        visible={paymentModalVisible}
        onCancel={handlePaymentModalClose}
        onPay={handlePaymentAction} // Handle payment based on action
        loading={paymentLoading}
        action={paymentAction} // 'activate', 'renew', or 'reactivate'
        onSuccess={handlePaymentSuccess}
      />
    </>
  );
};


export default YourSubscription;
