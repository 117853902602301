// src/components/UserSettings/UserSettings.js

import React, { useState , useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Layout, Row, Col, Card } from 'antd';
import { UserOutlined, LockOutlined, LeftOutlined } from '@ant-design/icons';
import Profile from './Profile/Profile';

import TwoFactorAuthentication from './EnableTwoFactorAuthentication/EnableTwoFactorAuthentication';

const { Content } = Layout;
const { TabPane } = Tabs;

const UserSettings = () => {
    // Optional: Manage active tab state for additional control
    const [activeTab, setActiveTab] = useState('1');

    const tabs = [
        {
            label: (
                <>
                    <UserOutlined /> Profile
                </>
            ),
            key: '1',
            children: <Profile />,
        },
        {
            label: (
                <>
                    <LockOutlined /> Two Factor Authentication
                </>
            ),
            key: '2',
            children: <TwoFactorAuthentication />,
        },
        // Uncomment and add more tabs as needed
        // {
        //     label: `Settings`,
        //     key: '3',
        //     children: <EditDoctorDetails />,
        // }
    ];

    // Handler for tab change (optional)
    const handleTabChange = (key) => {
        setActiveTab(key);
        // Perform any additional actions on tab change here
    };

    return (
        <Content
            style={{
                padding: '20px',
                height: '80vh', // Ensure Content takes full viewport height
                boxSizing: 'border-box',
                overflow: 'auto', 
            }}
            // className="site-layout-background"
        >
            <Row style={{ height: '100%', display: 'flex', padding: '10px' }}>
                <Col span={24} style={{ padding: '10px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Card
                        style={{
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                        }}
                        bodyStyle={{ flex: 1, overflow: 'auto' }} // Ensure Card body takes remaining space
                    >
                        <Link
                            to="/synopsis"
                            style={{
                                fontSize: '20px',
                                fontWeight: 600,
                                paddingLeft: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                            }}
                        >
                            <LeftOutlined style={{ marginRight: '8px' }} />
                            Back to Interview
                        </Link>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                            <Tabs
                                activeKey={activeTab} // Controlled Tabs (optional)
                                onChange={handleTabChange} // Handler for tab changes (optional)
                                tabPosition="left"
                                style={{ flex: 1, overflow: 'auto' }} // Allow Tabs to take available space and scroll if needed
                                destroyInactiveTabPane={true} // Ensure inactive tabs are unmounted
                            >
                                {tabs.map((tab) => (
                                    <TabPane tab={tab.label} key={tab.key}>
                                        {tab.children}
                                    </TabPane>
                                ))}
                            </Tabs>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
};

export default UserSettings;
