// src/components/LayoutWithHeader.jsx

import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
    Layout,
    Avatar,
    Row,
    Col,
    Spin,
    Menu,
    Dropdown,
    Tooltip,
    Switch, // Import Switch for theme toggle
    message
} from 'antd';
import {
    SettingOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    FileDoneOutlined,
    CommentOutlined,
    ReconciliationOutlined,
    DollarOutlined
} from '@ant-design/icons';
import { ConfigProvider, theme as antdTheme } from 'antd'; // Import ConfigProvider and theme
import ThemeContext from '../../context/ThemeContext'
import { useAuth } from '../../auth/AuthContext';
import AppFooter from '../AppFooter/AppFooter';
import FeedbackModal from '../FeedbackModal/FeedbackModal'; // Import the FeedbackModal component
import fullLogo from '../../assets/images/logo-no-background.png';
import {jwtDecode} from 'jwt-decode'; // Corrected import
import './LayoutWithHeader.css'; // Import the CSS file for additional styling
import '../../assets/css/PhoneInputOverrides.css';

const { Header } = Layout;

const LayoutWithHeader = () => {
    // Theme State: Manage the current theme ('light' or 'dark')
    const [themeMode, setThemeMode] = useState('light');

    // Other State Variables
    const [collapsed, setCollapsed] = useState(window.innerWidth <= 600);
    const [userDetails, setUserDetails] = useState(null);
    const auth = useAuth();
    const navigate = useNavigate();

    // State for Feedback Modal
    const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);

    // Destructure algorithms from Ant Design's theme
    const { darkAlgorithm, defaultAlgorithm } = antdTheme;

    // Load theme from localStorage on initial render
    useEffect(() => {
        const storedTheme = localStorage.getItem('app-theme');
        if (storedTheme) {
            setThemeMode(storedTheme);
        }
    }, []);

    // Persist theme changes to localStorage whenever themeMode changes
    useEffect(() => {
        localStorage.setItem('app-theme', themeMode);
    }, [themeMode]);

    // Function to toggle theme between light and dark
    const toggleTheme = (checked) => {
        setThemeMode(checked ? 'dark' : 'light');
    };

    // Fetch User Details
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/get_user`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${auth.user['access_token']}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    localStorage.setItem("userDetails", JSON.stringify(data));
                    setUserDetails(data);
                } else {
                    setUserDetails(null);
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
                setUserDetails(null);
            }
        };

        fetchUserDetails();
    }, [auth.user]);

    // Handle Token Expiration
    useEffect(() => {
        if (auth.user && auth.user['access_token']) {
            try {
                const decodedToken = jwtDecode(auth.user['access_token']);
                const currentTime = Date.now() / 1000; // Convert to seconds

                const expirationTime = decodedToken.exp ?? 0; // Fallback to 0 if exp is undefined

                const timeUntilExpiration = (expirationTime - currentTime) * 1000; // Convert to milliseconds

                if (timeUntilExpiration > 0) {
                    const logoutTimer = setTimeout(() => {
                        auth.logout();
                        navigate('/login');
                    }, timeUntilExpiration);

                    return () => clearTimeout(logoutTimer); // Clear the timer if the component unmounts
                } else {
                    // Token already expired
                    auth.logout();
                    navigate('/login');
                }
            } catch (error) {
                console.error("Error decoding token:", error);
                auth.logout();
                navigate('/login');
            }
        }
    }, [auth.user, navigate]);

    // Handle Logout
    const handleLogout = () => {
        auth.logout();
        navigate('/login');
    };

    // Menu for Avatar Dropdown
    const menu = (
        <Menu>
            <Menu.Item
                key="profile"
                icon={<SettingOutlined />}
                onClick={() => navigate('/userSettings')}
                style={{ padding: "10px 20px" }}
            >
                Settings
            </Menu.Item>
            {userDetails && !userDetails.bypass_subscription &&
            <Menu.Item
                key="subscription"
                icon={<DollarOutlined />}
                onClick={() => navigate('/subscription')}
                style={{ padding: "10px 20px" }}
            >
                Subscription
            </Menu.Item>}
            <Menu.Item
                key="configureTemplate"
                icon={<FileDoneOutlined />}
                onClick={() => navigate('/configureTemplate')}
                style={{ padding: "10px 20px" }}
            >
                Configure Template
            </Menu.Item>
            <Menu.Item
                key="patientManagement"
                icon={<ReconciliationOutlined />}
                onClick={() => navigate('/patientManagement')}
                style={{ padding: "10px 20px" }}
            >
                Patient Management
            </Menu.Item>
            <Menu.Item
                key="logout"
                icon={<LogoutOutlined />}
                onClick={handleLogout}
                style={{ padding: "10px 20px" }}
            >
                Logout
            </Menu.Item>
        </Menu>
    );

    // Function to open Feedback Modal
    const showFeedbackModal = () => {
        setIsFeedbackModalVisible(true);
    };

    // Function to close Feedback Modal
    const handleFeedbackCancel = () => {
        setIsFeedbackModalVisible(false);
    };

    return (
        <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
            <ConfigProvider
                theme={{
                    algorithm: themeMode === 'dark' ? darkAlgorithm : defaultAlgorithm,
                    token: {
                        colorPrimary: themeMode === 'dark' ? '#1890ff' : '#0070f2',
                        // You can customize more Ant Design tokens here
                    },
                }}
            >
                {/* Apply theme-specific class to the root div */}
                <div className={`layout-container ${themeMode}`}>
                    {/* Show loading spinner if userDetails is not fetched */}
                    {!userDetails ? (
                        <div style={{ textAlign: "center", padding: "30px" }}>
                            <Spin tip="Loading" size="large">
                                <div className="content" />
                            </Spin>
                        </div>
                    ) : (
                        <Layout style={{ minHeight: "100vh" }}>
                            <Header
                                style={{
                                    padding: "0 20px",
                                    background: themeMode === 'dark' ? '#1f1f1f' : '#fff', // Dynamic background color
                                    boxShadow: themeMode === 'dark' ? "none" : "0px 6px 5px -2px rgba(194,194,194,1)"
                                }}
                            >
                                <Row justify="space-between" align="middle">
                                    {/* Left Side: Logo */}
                                    <Col>
                                        <img
                                            src={fullLogo}
                                            alt="Sporo Health Logo"
                                            width={'150px'}
                                            style={{ padding: "15px 0px", cursor: "pointer" }}
                                            onClick={() => navigate('/synopsis')}
                                        />
                                    </Col>

                                    {/* Right Side: Theme Switch, Icons, and Avatar */}
                                    <Col>
                                        <Row align="middle" gutter={[16, 16]}>
                                            {/* Theme Switch */}
                                            <Col>
                                                <Tooltip title="Toggle Light/Dark Theme">
                                                    <Switch
                                                        checked={themeMode === 'dark'}
                                                        onChange={toggleTheme}
                                                        checkedChildren="Dark"
                                                        unCheckedChildren="Light"
                                                        style={{marginTop: "-20px"}}
                                                    />
                                                </Tooltip>
                                            </Col>

                                            {/* Share Feedback Icon */}
                                            <Col>
                                                <Tooltip placement="bottom" title="Share your feedback with us!">
                                                    <CommentOutlined
                                                        style={{ fontSize: '36px', color: "#0074D9" }}
                                                        onClick={showFeedbackModal}
                                                    />
                                                </Tooltip>
                                            </Col>

                                            {/* Help Icon */}
                                            <Col>
                                                <Tooltip placement="bottom" title="How to Explain Sporo Health AI Scribe to Your Patient">
                                                    <a
                                                        href="https://dev.azure.com/sporohealth/Secure%20System%20Engineering%20Documentation/_wiki/wikis/Secure-System-Engineering-Documentation.wiki/7/How-to-Explain-Sporo-Health-AI-Scribe-to-Your-Patient"
                                                        target='_blank'
                                                        rel="noopener noreferrer"
                                                    >
                                                        <QuestionCircleOutlined
                                                            style={{ fontSize: '36px', color: "#0074D9" }}
                                                        />
                                                    </a>
                                                </Tooltip>
                                            </Col>

                                            {/* User Avatar Dropdown */}
                                            <Col>
                                                <Dropdown overlay={menu} trigger={['click']}>
                                                    <Avatar
                                                        size={'large'}
                                                        style={{
                                                            backgroundColor: "#0074D9",
                                                            cursor: 'pointer',
                                                            marginTop: "-20px"
                                                        }}
                                                    >
                                                        {`${userDetails.first_name[0]}${userDetails.last_name[0]}`}
                                                    </Avatar>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Header>

                            {/* Main Content */}
                            <Outlet />

                            {/* Footer */}
                            <AppFooter width={"92%"} rightAlign={1} />

                            {/* Feedback Modal */}
                            <FeedbackModal
                                visible={isFeedbackModalVisible}
                                onCancel={handleFeedbackCancel}
                                onSuccess={() => {
                                    // Optional: Add any additional actions after successful feedback submission
                                }}
                            />
                        </Layout>
                    )}
                </div>
            </ConfigProvider>
        </ThemeContext.Provider>
    );

};

export default LayoutWithHeader;
