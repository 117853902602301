// src/components/TemplateSelector/TemplateSelector.js

import React, {useState, useEffect} from 'react';
import { Layout, Row, Col, Card, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import ExistingTemplate from './ExistingTemplate/ExistingTemplate';
import CustomTemplate from './CustomTemplate/CustomTemplate';
import { useAuth } from '../../auth/AuthContext';

const { Content } = Layout;
const { TabPane } = Tabs;

const TemplateSelector = () => {
  const auth = useAuth();
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false); 

  // Fetch User Details
  useEffect(() => {
    const fetchUserDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get_user`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                }
            });
            if (response.ok) {
                const data = await response.json();
                const expiryDate = new Date(data.subscription_expiry_date);
                const hasExpired = expiryDate < new Date();
                if (data.subscription_status && (data.subscription_status === 'active' || (data.subscription_status === 'canceled' && !hasExpired))){
                    setHasActiveSubscription(true)
                }
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    fetchUserDetails();
}, []);

  return (
    <Content
      style={{
        padding: '20px',
        height: '80vh', // Ensure Content takes full viewport height
        boxSizing: 'border-box',
        overflow: 'auto',
      }}
      // className="site-layout-background"
    >
      <Row style={{ height: '100%', display: 'flex', padding: '10px' }}>
        <Col span={24} style={{ padding: '10px', display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Card
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
            bodyStyle={{ flex: 1, overflow: 'auto' }} // Ensure Card body takes remaining space
          >
            <Link
              to="/synopsis"
              style={{
                fontSize: '20px',
                fontWeight: 600,
                paddingLeft: '20px',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
              }}
            >
              <LeftOutlined style={{ marginRight: '8px' }} />
              Back to Interview
            </Link>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
              <Tabs
                defaultActiveKey="1"
                tabPosition="left"
                style={{ height: '100%' }}
                destroyInactiveTabPane={true} // Add this line
              >
                <TabPane tab="Predefined Template" key="1">
                  <ExistingTemplate hasActiveSubscription={hasActiveSubscription} />
                </TabPane>
                <TabPane tab="Custom Template" key="2">
                  <CustomTemplate hasActiveSubscription={hasActiveSubscription} />
                </TabPane>
              </Tabs>
            </div>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default TemplateSelector;
