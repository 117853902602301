// src/components/PaymentModal.js

import React from 'react';
import { Modal, Card, Typography, Button, Row, Col, Divider } from 'antd';
import { DollarOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './PaymentModal.css'; // Import custom CSS

const { Title, Text } = Typography;

const PaymentModal = ({
  visible,
  onCancel,
  onPay,
  loading,
  action,
  onSuccess,
}) => {
  
  // Determine modal title based on action
  const getTitle = () => {
    switch (action) {
      case 'activate':
        return 'Activate Your Subscription';
      case 'renew':
        return 'Renew Your Subscription';
      case 'reactivate':
        return 'Reactivate Your Subscription';
      default:
        return 'Subscribe to a Plan';
    }
  };

  // Determine button text based on action
  const getButtonText = () => {
    switch (action) {
      case 'activate':
        return 'Activate Subscription';
      case 'renew':
        return 'Renew Subscription';
      case 'reactivate':
        return 'Reactivate Subscription';
      default:
        return 'Proceed to Payment';
    }
  };

  // Determine description based on action
  const getDescription = () => {
    switch (action) {
      case 'activate':
        return 'Subscribe now to gain access to all premium features, including unlimited interviews, detailed analytics, and priority support.';
      case 'renew':
        return 'Renew your subscription to continue enjoying all premium features.';
      case 'reactivate':
        return `Reactivate your subscription to regain access to premium features before your current period ends. Your card won't be charged for this current billing cycle.`;
      default:
        return 'Subscribe to our premium plan to enjoy exclusive benefits.';
    }
  };

  // Determine if the modal should be closable
  const isClosable = action !== 'activate';

  // Optionally prevent closing the modal via clicking the mask or pressing the Esc key for 'activate'
  const maskClosable = action !== 'activate';
  const keyboard = action !== 'activate';

  return (
    <Modal
      visible={visible}
      title={getTitle()}
      onCancel={onCancel}
      footer={null} // We'll handle the footer inside the Card
      centered
      width={500}
      className="payment-modal"
      closable={isClosable} // Conditionally show close button
      maskClosable={maskClosable} // Conditionally allow closing by clicking the mask
      keyboard={keyboard} // Conditionally allow closing via Esc key
      destroyOnClose={true} // Optional: Destroy modal content on close to reset state
    >
      <Card bordered={false} className="payment-card">
        <Row justify="center" align="middle" style={{ marginBottom: '20px' }}>
          <CheckCircleOutlined style={{ fontSize: '50px', color: '#52c41a' }} />
        </Row>
        <Row justify="center" align="middle">
          <Title level={3}>Premium Plan</Title>
        </Row>
        <Row justify="center" align="middle" style={{ marginBottom: '20px' }}>
          <DollarOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
          <Title level={2} style={{ margin: 0 }}>
            $199.00 USD / Month
          </Title>
        </Row>
        <Divider />
        <div style={{ textAlign: 'left' }}>
          <Text>
            <b>Plan Details:</b>
          </Text>
          <ul>
            <li>Unlimited interviews</li>
            <li>Detailed analytics</li>
            <li>Priority support</li>
          </ul>
        </div>
        <Divider />
        <Row justify="center" align="middle" style={{ marginBottom: '30px' }}>
          <Text>{getDescription()}</Text>
        </Row>
        <Row justify="center" align="middle">
          <Button
            type="primary"
            size="large"
            onClick={onPay}
            loading={loading}
            style={{ width: '100%' }}
          >
            {getButtonText()}
          </Button>
        </Row>
      </Card>
    </Modal>
  );
};

export default PaymentModal;
