// src/pages/StripeRedirectHandler.js

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Updated import
import { message } from 'antd';
import { useAuth } from '../../auth/AuthContext';

const StripeRedirectHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const status = query.get('status');
    const session_id = query.get('session_id');

    const handleRedirect = async () => {
      if (status === 'success' && session_id) {
        // Verify the session with the backend
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/payments/session-status?session_id=${session_id}`,
            {
              headers: {
                Authorization: `Bearer ${auth.user['access_token']}`,
              },
              method: 'GET',
            }
          );

          if (response.ok) {
            const result = await response.json();
            const { current_status } = result;

            if (current_status === 'active') {
              message.success('Payment successful! Subscription activated.');
              // Redirect to dashboard or desired page
              navigate('/dashboard');
            } else if (current_status === 'canceled') {
              message.success('Payment successful! Subscription set to cancel at period end.');
              navigate('/dashboard');
            } else {
              message.warning('Payment was successful, but subscription status is not active.');
              navigate('/dashboard');
            }
          } else {
            const errorData = await response.json();
            message.error(
              `Failed to verify payment status: ${
                errorData.detail || 'Unknown error'
              }`
            );
            navigate('/dashboard');
          }
        } catch (error) {
          console.error('Error verifying payment session:', error);
          message.error('Error verifying payment session.');
          navigate('/dashboard');
        }
      } else if (status === 'cancelled') {
        message.warning('Payment was cancelled. Subscription not activated.');
        navigate('/dashboard');
      } else {
        message.error('Unknown payment status.');
        navigate('/dashboard');
      }
    };

    handleRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null; // Render nothing
};

export default StripeRedirectHandler;
