// src/App.tsx
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';
import Login from './components/Login/Login';
import Signup from './components/Signup/Signup';
import VerificationAwaiting from './components/Signup/VerificationAwaiting/VerificationAwaiting';
import LayoutWithHeader from './components/LayoutWithHeader/LayoutWithHeader';
import "./App.css";
import {useAuth} from "./auth/AuthContext";
import ChangePassword from './components/ChangePassword/ChangePassword';
import ResetPassword from './components/Password/OTPValidation/OTPValidation';
import ForgotPassword from './components/Password/ForgotPassword/ForgotPassword';
import VerifyEmail from './components/Signup/VerifyEmail/VerifyEmail';
import VerifiedEmail from './components/Signup/VerifiedEmail/VerifiedEmail';
import LinkedInCallback from './components/Login/LinkedInLogin/LinkedInCallback';
import GoogleCallback from './components/Login/GoogleLogin/GoogleCallback';
import MicrosoftCallback from './components/Login/MicrosoftLogin/MicrosoftCallback';
import TwoFactorAuthentication from './components/Login/TwoFactorAuthentication/TwoFactorAuthentication';
import Synopsis from './components/Synopsis/Synopsis';
import UserSettings from './components/UserSettings/UserSettings'
import TemplateSelector from './components/TemplateSelector/TemplateSelector';
import PatientDetails from './components/PatientDetails/PatientDetails';
import StripeRedirectHandler from './components/StripeRedirectHandler/StripeRedirectHandler';
import Subscription from './components/Subscription/Subscription';


function App() {
  const auth = useAuth();
  return (
    <div>
        <Routes>
            <Route path="/login" element={<Login />}  />
            <Route path="/twoFactorAuthentication" element={<TwoFactorAuthentication />}  />
            <Route path="/signup" element={<Signup />}  />
            <Route path="/verificationAwaiting" element={<VerificationAwaiting />}  />
            <Route path="/verify-email" element={<VerifyEmail />}  />
            <Route path="/email-verified" element={<VerifiedEmail />}  />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/" element={<ResetPassword/>} />
            <Route path="/change-password" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
            <Route path="/callback/linkedin" element={<LinkedInCallback />} />
            <Route path="/callback/google" element={<GoogleCallback />} />
            <Route path="/callback/microsoft" element={<MicrosoftCallback />} />
            <Route element={<ProtectedRoute><LayoutWithHeader /></ProtectedRoute>}>
              <Route path="/synopsis" element={<Synopsis />} />
              <Route path="/userSettings" element={<UserSettings />} />
              <Route path="/configureTemplate" element={<TemplateSelector />} />
              <Route path="/patientManagement" element={<PatientDetails />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/return" component={StripeRedirectHandler} />
            </Route>
            <Route path="/" element={auth.user ? <Navigate to="/synopsis" /> : <Login />} />
        </Routes>
    </div>
  );
}

export default App;
