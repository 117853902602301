// src/components/TemplateSelector/ExistingTemplate.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Spin,
  Radio,
  Space,
  Typography,
  message,
  Row,
  Col,
  Button,
  Modal,
  Divider
} from 'antd';
import { useAuth } from '../../../auth/AuthContext';
import ThemeContext from '../../../context/ThemeContext';
import '../../../assets/css/ModalStyle.css'

const { Text, Title } = Typography;
const { confirm } = Modal;

const ExistingTemplate = ({hasActiveSubscription}) => {  
  const { themeMode } = useContext(ThemeContext);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const auth = useAuth();

  useEffect(() => {
    setLoading(true);
    fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/templates`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'GET',
      });

      if (response.ok) {
        const result = await response.json();
        const userDetails = localStorage.getItem("userDetails");
        const user = userDetails && JSON.parse(userDetails);
        const templates = result.data.filter(r=> r.community_template && ((r.user_id === null && r.public) || (r.user_id === user.user_id && !r.public)))

        setTemplates(templates);

        // Fetch user preferences
        const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/get_user`, {
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
          method: 'GET',
        });

        if (userResponse.ok) {
          const user = await userResponse.json();
          if (user.preferred_template_id) {
            setSelectedTemplateId(user.preferred_template_id);
          } else if (templates.length > 0) {
            setSelectedTemplateId(templates[0]._id);
          }
        } else if (templates.length > 0) {
          setSelectedTemplateId(templates[0]._id);
        }
      } else {
        message.error('Failed to fetch summary templates');
      }
    } catch (error) {
      console.error('Error fetching summary templates', error);
      message.error('Error fetching summary templates');
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setSelectedTemplateId(e.target.value);
  };

  const handleSubmit = async () => {
    setSaving(true); // Start saving
    try {
      const formData = new FormData();
      formData.append('template_id', selectedTemplateId);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/templates/user/select`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        message.success('Selected template saved successfully');
      } else {
        message.error('Failed to save the selected template');
      }
    } catch (error) {
      console.error('Error saving the selected template', error);
      message.error('Error saving the selected template');
    }
    setSaving(false); // End saving
  };

  const showConfirm = () => {
    confirm({
      title: 'Are you sure you want to save the selected template?',
      content: 'This action will update your preferred summary template.',
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      centered: true,
      className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
      onOk() {
        handleSubmit();
      },
      onCancel() {
        console.info('Save cancelled');
      },
    });
  };

  return (
    <>
      <Title level={2} style={{ marginTop: '0px', paddingLeft: '20px' }}>
        Select Predefined Summary Template
      </Title>
      <Divider style={{ marginTop: '0px', paddingLeft: '20px' }} />
      {loading ? (
        <Spin
          size="large"
          style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
        />
      ) : (
        <>
          <Radio.Group
            onChange={handleChange}
            value={selectedTemplateId}
            style={{ width: '100%', padding: '10px 20px' }}
          >
            <Row gutter={[16, 16]}>
              {templates.length > 0 ? (
                templates.map((template) => (
                  <Col xs={24} sm={12} key={template._id}>
                    <Radio
                      value={template._id}
                      style={{
                        width: '100%',
                        padding: '10px',
                        border:
                          selectedTemplateId === template._id
                            ? '2px solid #2fed78'
                            : '1px solid #d9d9d9',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        transition: 'border 0.3s ease',
                      }}
                    >
                      <Space direction="vertical">
                        <Text strong>{template.name}</Text>
                        <Text type="secondary">
                          {template.short_description
                            ? template.short_description
                            : 'No description available.'}
                        </Text>
                      </Space>
                    </Radio>
                  </Col>
                ))
              ) : (
                <Col span={24}>
                  <Text type="warning">No templates available.</Text>
                </Col>
              )}
            </Row>
          </Radio.Group>
          <Col
            span={24}
            style={{ textAlign: 'right', padding: '0px 25px', marginTop: '20px' }}
          >
            <Button type="primary" onClick={showConfirm} loading={saving} disabled={!hasActiveSubscription}>
              Save
            </Button>
          </Col>
        </>
      )}
    </>
  );
};

export default ExistingTemplate;
