// src/components/TemplateSelector/EditTemplateModal.js

import React from 'react';
import { Modal, Form, Input, Checkbox, Button, Upload, Row, Col, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const EditTemplateModal = ({
    visible,
    onCancel,
    onSave,
    saving,
    form,
    themeMode,
    loading,
    template,
}) => {
    // Custom validator to ensure exactly one of file or free_text is provided
    const validateEitherFileOrText = (_, value) => {
        const file = form.getFieldValue('file');
        const freeText = form.getFieldValue('free_text');
        if ((file && file.length > 0) && (freeText && freeText.trim() !== '')) {
            return Promise.reject(new Error('Please provide either a file or paste your template, not both.'));
        }
        if ((!file || file.length === 0) && (!freeText || freeText.trim() === '')) {
            return Promise.reject(new Error('Please provide either a file or paste your template.'));
        }
        return Promise.resolve();
    };

    return (
        <Modal
            title="Edit Custom Template"
            visible={visible}
            onCancel={onCancel}
            footer={null} // We'll use Form's submit button
            destroyOnClose
            maskClosable={false}
            centered
            className={themeMode === 'dark' ? 'dark-modal' : 'light-modal'}
        >
            {loading ? (
                <Spin
                    size="large"
                    style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                />
            ) : (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSave}
                    initialValues={{
                        public: false,
                    }}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            { required: true, message: 'Please enter the template name' },
                            { max: 100, message: 'Name cannot exceed 100 characters' },
                        ]}
                    >
                        <Input placeholder="Enter template name" />
                    </Form.Item>

                    <Form.Item
                        label="Short Description"
                        name="short_description"
                        rules={[
                            { required: true, message: 'Please enter a short description' },
                            { max: 200, message: 'Description cannot exceed 200 characters' },
                        ]}
                    >
                        <Input placeholder="Enter short description" />
                    </Form.Item>

                    <Form.Item name="public" valuePropName="checked">
                        <Checkbox>Make this template public</Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="Upload .txt File"
                        name="file"
                        valuePropName="file"
                        getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
                        rules={[
                            {
                                validator: validateEitherFileOrText,
                            },
                        ]}
                        dependencies={['free_text']}
                    >
                        <Upload
                            beforeUpload={() => false} // Prevent automatic upload
                            maxCount={1}
                            accept=".txt"
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="Or Paste your template"
                        name="free_text"
                        rules={[
                            {
                                validator: validateEitherFileOrText,
                            },
                        ]}
                        dependencies={['file']}
                    >
                        <TextArea rows={4} placeholder="Paste your template" />
                    </Form.Item>

                    <Form.Item>
                        <Row gutter={16} justify="end">
                            <Col>
                                <Button onClick={onCancel}>Cancel</Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit" loading={saving}>
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};

export default EditTemplateModal;
