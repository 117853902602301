import React, {useState} from 'react';
import { Tabs } from 'antd';
import CustomizedPredefinedTemplate from './CustomizedPredefinedTemplate/CustomizedPredefinedTemplate';
import YourCustomTemplate from './YourCustomTemplate/YourCustomTemplate';

const { TabPane } = Tabs;

const CustomTemplate = ({hasActiveSubscription}) => {
    const [resetKey, setResetKey] = useState(0);

    const handleTabChange = (activeKey) => {
        // Increment resetKey to trigger re-mounting of child components
        setResetKey(prevKey => prevKey + 1);
    };

    return (
        <Tabs
            defaultActiveKey="1"
            onChange={handleTabChange}
            
            destroyInactiveTabPane={false} // We'll manage unmounting via resetKey
        >
            <TabPane tab="Customize Predefined Template" key="1">
                <CustomizedPredefinedTemplate key={`customized-${resetKey}`} hasActiveSubscription={hasActiveSubscription} />
            </TabPane>
            <TabPane tab="Your Custom Template" key="2">
                <YourCustomTemplate key={`yourcustom-${resetKey}`} hasActiveSubscription={hasActiveSubscription} />
            </TabPane>
        </Tabs>
    );
};

export default CustomTemplate;